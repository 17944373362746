import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2024,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.July,
    year: 2024,
    offers: [
      {
        title: 'Cetirizin ratiopharm',
        description: 'bei Allergien, 20 Filmtabletten',
        imgFile: 'cetirizin_antiallergikum_20filmtabletten.png',
        offerPrice: 3.99,
      },
      {
        title: 'Loperamid akut',
        description: 'gegen Reisedurchfall, 10 Filmtabletten',
        imgFile: 'loperamid-ratiopharm-2-mg-10-filmtabletten.png',
        offerPrice: 2.99,
      },
      {
        title: 'Voltaren',
        description: '120g Schmerzgel',
        imgFile: 'voltaren-schmerzgel-120-g-mit-tube.png',
        offerPrice: 14.99,
      },
    ],
  },
  {
    month: Month.August,
    year: 2024,
    offers: [
      {
        title: 'FeniHydrocort 0,5%',
        description: 'bei mäßig entzündlichen Hauterkrankungen, 15g Creme',
        imgFile: 'Fenhydrocort_Creme_0_5.png',
        offerPrice: 7.99,
      },
      {
        title: 'Magnesium Verla',
        description: 'zur Magnesiumtherapie, 100 Filmtabletten',
        imgFile: 'magnesium_verla_200.png',
        offerPrice: 7.99,
      },
      {
        title: 'Vitamin B-Komplex',
        description: 'Nahrungsergänzungsmittel, 60 Hartkapseln',
        imgFile: 'ratiopharm Vitamin-B-Komplex.png',
        offerPrice: 15.99,
      },
    ],
  },
];

export default offersCollection;
